









































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarRateAlertConfigsPopup from '@/modules/cars/modules/settings/components/cars-rate-alert-configs-popup.vue';
import ExpansionPanel from '@/modules/common/components/ui-kit/expansion-panel.vue';
import CarRateAlertSettings from '@/modules/cars/modules/settings/components/cars-rate-alert-type.vue';
import CarsRateAlertService, { CarsRateAlertServiceS } from '@/modules/cars/car.rate-alert.service';
import { CarAlertSubTitle, CarAlertTypeList } from '../../enums/alerts.enum';

@Component({
    components: {
        CarRateAlertSettings,
        ExpansionPanel,
        CarRateAlertConfigsPopup,
    },
})

export default class CarAlertSettings extends Vue {
    @Inject(CarsRateAlertServiceS) private carsRateAlertService!: CarsRateAlertService;

    static title = 'Alerts & Notifications';
    public isPopupOpen = false;
    public isExpanded = false;

    public icons = {
        RATE_DIFF: 'icon-CI_Rateshopper',
        COMPSET_DIFF: 'icon-CI_Rateshopper',
        COMPETITORS_DIFF: 'icon-CI_Reviews',
    };

    get alertTypes() {
        return CarAlertTypeList;
    }

    get alertTypesSubTitle() {
        return CarAlertSubTitle;
    }

    openPopup() {
        this.isPopupOpen = true;
    }

    closePopup() {
        this.isPopupOpen = false;
    }
}
