





















import {
    Vue,
    Component,
    Prop,
    Watch,
} from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';

export interface IDropdownItem {
    text: string,
    action?: Function,
    disable?: boolean,
    isVisible?: boolean,
}

export type DropdownMenuAlignment = 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end';

@Component({
    components: {
        'el-dropdown': Dropdown,
        'el-dropdown-menu': DropdownMenu,
        'el-dropdown-item': DropdownItem,
    },
})
export default class CustomMenu extends Vue {
    @Prop({
        required: false,
        type: String,
        default: () => 'bottom-start',
    })
    private placement!: DropdownMenuAlignment;

    @Prop({
        required: false,
        type: String,
    })
    private label!: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private visible!: boolean;

    @Prop({
        required: true,
        type: Array as () => IDropdownItem[],
        validator(value: any): boolean {
            return value.every((item: any) => ('text' in item && 'action' in item
                && typeof item.text === 'string'
            ));
        },
    })
    private items!: IDropdownItem[];

    @Prop({
        required: true,
        type: String,
    })
    private values!: string;

    @Watch('visible')
    closeDropdown() {
        const element = this.$refs.dropdown as (Vue | Element | Vue[] | Element[]) & { hide: () => void };

        if (!this.visible) {
            element.hide();
        }
    }
}
