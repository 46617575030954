























































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import SippCodeMappingAlertsSettings from '../components/tabs/sippcode-mapping.tab.vue';
import CarAlertSettings from '../components/tabs/alerts-notifications.tab.vue';

@Component({
    components: {
        PageWrapper,
        TabsController,
    },
})
export default class SettingsPage extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;;

    tabs = [
        SippCodeMappingAlertsSettings,
        CarAlertSettings,
    ];

    currentTab = +this.$route.query.tab || 0;

    mounted() {
        const { dataSources } = this.carsSharedService.filters;
        if (!(dataSources?.length && dataSources?.includes('Brand'))) {
            this.tabs = [SippCodeMappingAlertsSettings];
            this.currentTab = 0;
            this.$route.query.tab = '0';
        }
    }

    setTab(index: number) {
        this.currentTab = index;
        this.updateQueryParameter();
    }

    updateQueryParameter() {
        if (this.currentTab === +this.$route.query.tab) return;

        this.$router.push({
            name: this.$route.name!,
            params: {
                ...this.$route.params,
            },
            query: { tab: String(this.currentTab) },
        });
    }
}
